export default {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
  themeWhite: '#ffffff',
  themeBlack: '#000000',
  turquoise: '#1abc9c',
  purple: '#9b59b6',
  yellow: '#f1c40f',
  maroon: '#700000',
  lightblue: '#3498db',
  orange: '#e67e22',
  hotpink: '#ff008c',
  green: '#2ecc71',
  red: '#e74c3c',
  darkblue: '#273c75',
  secondary: '#82868b',
}
