/* eslint-disable object-curly-newline */
const STATUS = {
  0: 'Unpaid',
  1: 'Active',
  2: 'Assigned SMARTTRUCK',
  3: 'Arrived at Sender',
  4: 'Arrived at Golog',
  5: 'In Transit',
  6: 'Picked Up by SMARTTRUCK',
  7: 'Out for Delivery',
  8: 'Delivered',
  9: 'Request For Cancellation',
  10: 'Cancelled',
  11: 'Request For Refund',
}

const STATUSCOLOR = {
  0: 'red',
  1: 'turquoise',
  2: 'purple',
  3: 'yellow',
  4: 'maroon',
  5: 'lightblue',
  6: 'orange',
  7: 'hotpink',
  8: 'green',
  9: 'red',
  10: 'red',
  11: 'red',
}

const STATE = [
  { text: 'Kuala Lumpur', value: 'Kuala Lumpur' },
  { text: 'Perlis', value: 'Perlis' },
  { text: 'Kedah', value: 'Kedah' },
  { text: 'Pulau Pinang', value: 'Pulau Pinang' },
  { text: 'Perak', value: 'Perak' },
  { text: 'Pahang', value: 'Pahang' },
  { text: 'Kelantan', value: 'Kelantan' },
  { text: 'Terengganu', value: 'Terengganu' },
  { text: 'Selangor', value: 'Selangor' },
  { text: 'Negeri Sembilan', value: 'Negeri Sembilan' },
  { text: 'Melaka', value: 'Melaka' },
  { text: 'Johor', value: 'Johor' },
  { text: 'Sabah', value: 'Sabah' },
  { text: 'Sarawak', value: 'Sarawak' },
  { text: 'Singapore', value: 'Singapore' },
]

const STATUS_OPTIONS = [
  { id: 1, text: 'Active' },
  { id: 2, text: 'Assigned SMARTTRUCK' },
  { id: 3, text: 'Arrived at Sender' },
  { id: 4, text: 'Arrived at Golog' },
  { id: 5, text: 'In Transit' },
  { id: 6, text: 'Picked Up by SMARTTRUCK' },
  { id: 7, text: 'Out for Delivery' },
  { id: 8, text: 'Delivered' },
]

const STATUS_OPTIONS_FLEET = [
  { id: 1, text: 'Active' },
  { id: 17, text: 'For Quotation' },
  { id: 12, text: 'Quoted' },
  { id: 14, text: 'Pending Payment' },
  { id: 2, text: 'Accepted' },
  { id: 3, text: 'Picked up' },
  { id: 4, text: 'Arrived at Origin Port' },
  { id: 5, text: 'In Transit' },
  { id: 6, text: 'Arrived at Destination Port' },
  { id: 7, text: 'Out For Delivery' },
  { id: 8, text: 'Delivered' },
]

export { STATUSCOLOR, STATUS, STATE, STATUS_OPTIONS, STATUS_OPTIONS_FLEET }
