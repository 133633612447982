<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          Statistics
        </b-card-title>
        <b-card-sub-title>Base on selected dates</b-card-sub-title>
      </div>
      <b-button-group class="mt-1 mt-sm-0">
        <b-button
          variant="outline-primary"
          :class="{ 'active': selectedFilterBy === 'daily' }"
          @click.prevent="filterGraphBy('daily')"
        >
          Daily
        </b-button>
        <b-button
          variant="outline-primary"
          :class="{ 'active': selectedFilterBy === 'monthly' }"
          @click.prevent="filterGraphBy('monthly')"
        >
          Monthly
        </b-button>
        <!-- <b-button
          variant="outline-primary"
          :class="{ 'active': selectedFilterBy === 'yearly' }"
          @click.prevent="filterGraphBy('yearly')"
        >
          Yearly
        </b-button> -->
      </b-button-group>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <line-chart
        :height="400"
        :data="chartData"
        :options="chartOptions"
        :plugins="plugins"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import LineChart from '@/components/Charts/LineChart.vue'
import chartColors from '@/common/ChartColors'

export default {
  name: 'AnalyticsLineGraph',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    LineChart,
  },
  props: {
    selectedFilterBy: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    chartData: {
      type: Object,
      default: () => {},
    },
    chartOptions: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
        },
        tooltips: {
        // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: chartColors.greyLightColor,
          titleFontColor: chartColors.themeBlack,
          bodyFontColor: chartColors.themeBlack,
        },
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: 10,
                min: 0,
                max: 100,
                fontColor: chartColors.labelColor,
              },
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      }),
    },
  },
  data() {
    return {
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  methods: {
    filterGraphBy(filter) {
      this.$emit('filterGraphBy', filter)
    },
  },
}
</script>
