<template>
  <b-modal
    id="admin-role-modal"
    ref="admin-role-modal"
    v-model="show"
    :title="!isEdit ? 'Add New Role' : 'Edit Selected Role'"
    size="lg"
    hide-footer
    no-close-on-backdrop
    centered
    @hidden="handleHiddenModal"
  >
    <ValidationObserver
      ref="adminRoleForm"
      v-slot="{ passes }"
    >
      <form @submit.prevent="passes(handleSubmit)">
        <b-row class="mt-2">
          <b-col md="12">
            <b-form-group
              label="Role Title"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="title"
                name="Role Title"
              >
                <b-form-input
                  v-model="form.title"
                  placeholder="Enter Role Title"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
          >
            <b-form-group
              label="Permission"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="permission"
                name="Permission"
              >
                <v-select
                  v-model="form.permission"
                  :options="pages"
                  label="title"
                  multiple
                  append-to-body
                  :calculate-position="withPopper"
                  placeholder="Select Permission"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-checkbox
              id="select-all"
              v-model="isCheckAll"
              name="select all"
              :value="true"
              :unchecked-value="false"
              @change="handleSelectAll"
            >
              <span class="text-primary">Select All Permission</span>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          class="float-right my-1"
          type="submit"
          :disabled="isLoading || (isEdit && form.title === null)"
        >
          <b-spinner
            v-if="isLoading"
            class="mr-50 p-0"
            small
          />
          <feather-icon
            v-else
            icon="SaveIcon"
            class="mr-50"
          />
          {{ !isEdit ? 'Add New Role' : 'Update Role' }}
        </b-button>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { createPopper } from '@popperjs/core'
import vSelect from 'vue-select'
import axios from '@axios'

export default {
  name: 'AddEditAdminUserRoleModal',
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    pages: {
      type: Array,
      required: true,
    },
    abilities: {
      type: Array,
      default: () => [],
    },
    selectedRole: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      isLoading: false,
      isCheckAll: false,
      form: {
        title: null,
        permission: [],
      },
      editSwalMessage: {
        icon: 'success',
        title: 'Updated',
        text: 'Successfully Updated Admin Role',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      },
      addSwalMessage: {
        icon: 'success',
        title: 'Created',
        text: 'Successfully Created Admin Role',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      },
    }
  },
  watch: {
    isShow(newValue) {
      this.show = newValue
    },
    selectedRole(newValue) {
      this.form = {
        title: newValue.title,
        permission: newValue.permission,
      }
    },
    form: {
      handler(newValue) {
        if (newValue.permission) {
          this.isCheckAll = newValue.permission.length === this.pages.length
        }
      },
      deep: true,
    },
  },
  methods: {
    handleHiddenModal() {
      this.show = false
      this.form.permission = []
      this.form.title = null
      this.isCheckAll = false
      this.$emit('reset-modal')
    },
    handleSelectAll(isCheck) {
      if (isCheck) {
        this.form.permission = this.pages
        return
      }
      this.form.permission = []
    },
    async handleSubmit() {
      try {
        this.isLoading = true
        const response = this.isEdit ? await axios.patch(`/admin-abilities/${this.selectedRole.id}`, this.form) : await axios.post('/admin-abilities', this.form)
        if (response.status === 422) {
          this.$refs.adminRoleForm.setErrors(response.data.errors)
          this.isLoading = false
          return
        }

        if (response.status === 201 || response.status === 200) {
          this.isLoading = false
          this.$swal(this.isEdit ? this.editSwalMessage : this.addSwalMessage)
            .then(() => {
              this.handleHiddenModal()
              this.form = {
                title: null,
                permission: [],
              }
              this.$emit('success')
            })
        }
      } catch (error) {
        throw new Error('Something went wrong.', error)
      }
    },
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      const tempDropDown = dropdownList
      tempDropDown.style.width = width
      tempDropDown.style.zIndex = '1300'

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, tempDropDown, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
  },
}
</script>
