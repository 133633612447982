var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"new-admin-user-modal",attrs:{"id":"new-admin-user-modal","title":!_vm.isEdit ? 'Add New User' : 'Edit User',"size":"lg","hide-footer":"","no-close-on-backdrop":"","centered":""},on:{"hidden":_vm.handleHiddenModal},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('ValidationObserver',{ref:"adminUserForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.handleSubmit)}}},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Full Name"}},[_c('validation-provider',{attrs:{"vid":"name","name":"Full Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Full Name (e.g. John Doe)"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email Address"}},[_c('validation-provider',{attrs:{"vid":"email","name":"Email Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Email Address"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Role"}},[_c('validation-provider',{attrs:{"vid":"ability_id","name":"Role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.abilities,"clearable":false,"label":"title","reduce":function (ability) { return ability.id; },"append-to-body":"","calculate-position":_vm.withPopper,"placeholder":"Select Role"},model:{value:(_vm.form.ability_id),callback:function ($$v) {_vm.$set(_vm.form, "ability_id", $$v)},expression:"form.ability_id"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.isEdit)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Status"}},[_c('validation-provider',{attrs:{"vid":"status","name":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.statuses,"clearable":false,"label":"text","reduce":function (status) { return status.value; },"append-to-body":"","calculate-position":_vm.withPopper,"placeholder":"Select Status"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('span',{staticClass:"text-info"},[_vm._v("Note: Random Password will be sent to the specified Email Address.")])])],1),_c('b-button',{staticClass:"float-right my-1",attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"mr-50 p-0",attrs:{"small":""}}):_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(!_vm.isEdit ? 'Add New User' : 'Update User')+" ")],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }