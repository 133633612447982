<template>
  <div>
    <StatisticCardVertical
      :color="statistics.color"
      :icon="statistics.icon"
      :statistic="statistics.statistic"
      :statistic-title="statistics.statisticTitle"
      class="m-0"
    />
  </div>
</template>

<script>
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  name: 'StatisticsCard',
  components: {
    StatisticCardVertical,
  },
  props: {
    statistics: {
      required: true,
      type: [Array, Object],
    },
  },
  data() {
    return {
      //
    }
  },
}
</script>
