<template>
  <div>
    <b-card>
      <b-row class="d-flex align-items-center">
        <b-col
          md="12"
          lg="3"
        >
          <h2 class="font-weight-bolder m-0">
            {{ title }}
          </h2>
        </b-col>
        <b-col
          md="12"
          lg="3"
        >
          <b-form-group label="Select From Date">
            <b-input-group>
              <flatPickr
                v-model="date.from"
                :config="fromDateConfig"
                class="form-control"
                placeholder="Select Date"
              />

              <b-input-group-append>
                <b-button
                  title="Toggle"
                  data-toggle
                  variant="outline-primary"
                  disabled
                >
                  <FeatherIcon icon="CalendarIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          md="12"
          lg="3"
        >
          <b-form-group label="Select To Date">
            <b-input-group>
              <flatPickr
                v-model="date.to"
                :config="toDateConfig"
                class="form-control"
                placeholder="Select Date"
              />

              <b-input-group-append>
                <b-button
                  title="Toggle"
                  data-toggle
                  variant="outline-primary"
                  disabled
                >
                  <FeatherIcon icon="CalendarIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          md="12"
          lg="3"
          class="text-center"
        >
          <b-button
            class="mr-1"
            variant="primary"
            style="margin-top: 13px;"
            :disabled="isLoading"
            @click="$emit('handleExtract', date)"
          >
            <b-spinner
              v-if="isLoading"
              class="mr-50 p-0"
              small
            />
            Extract Analytics
          </b-button>

          <b-dropdown
            style="margin-top: 13px;"
            split
            variant="outline-danger"
            menu-class="w-100"
            class="w-30"
          >
            <template #button-content>
              Export
            </template>
            <b-dropdown-item
              v-for="(type, index) in exportType"
              :key="index"
              @click.prevent="$emit('handleExport', type.value)"
            >
              {{ type.text }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'AnalyticsDateSelector',
  components: {
    flatPickr,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: {
        from: '',
        to: '',
      },
      exportType: [
        // { value: 'pdf', text: 'PDF' },
        { value: 'xlsx', text: 'Excel' },
        { value: 'csv', text: 'CSV' },
      ],
    }
  },
  computed: {
    fromDateConfig() {
      return {
        enableTime: false,
        dateFormat: 'Y-m-d 00:00:00',
        altFormat: 'F j, Y',
        defaultDate: Date.now(),
        altInput: true,
      }
    },
    toDateConfig() {
      return {
        enableTime: false,
        dateFormat: 'Y-m-d 23:59:59',
        minDate: this.date.from ? this.date.from : Date.now(),
        altFormat: 'F j, Y',
        defaultDate: Date.now(),
        altInput: true,
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
