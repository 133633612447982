<template>
  <b-card
    no-body
    class="card-employee-task"
  >
    <b-card-header>
      <b-card-title class="text-uppercase">
        <h1>
          <strong>
            <FeatherIcon
              icon="BoxIcon"
              size="30"
            /> Order Summary
          </strong>
        </h1>
      </b-card-title>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
        >
          <b-list-group horizontal="lg">
            <b-list-group-item class="border-0 pb-0 w-100">
              <h4>
                <strong>Order ID:</strong>
              </h4>
              <h4 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.reference_id }}</span>
              </h4>
            </b-list-group-item>
            <b-list-group-item class="border-0 pb-0 w-100">
              <h4>
                <strong>Status:</strong>
              </h4>
              <h4 class="pb-1">
                <span
                  :class="`text-${order.status.color_name} d-block font-weight-bolder`"
                >
                  {{ order.status.text }}
                </span>
              </h4>
            </b-list-group-item>
            <b-list-group-item class="border-0 pb-0 w-100">
              <h4>
                <strong>Total Earn:</strong>
              </h4>
              <h4 class="pb-1">
                <span class="d-block font-weight-normal">{{ $helpers.formatTotal(+order.smarttruck_cut + +order.pickup_charge) }}</span>
              </h4>
            </b-list-group-item>
            <b-list-group-item class="border-0 pb-0 w-100">
              <h4>
                <strong>Order Added:</strong>
              </h4>
              <h4 class="pb-1">
                <span class="d-block font-weight-normal">{{ $helpers.formatDateTime(order.date_added) }}</span>
              </h4>
            </b-list-group-item>
            <b-list-group-item class="border-0 pb-0 w-100">
              <h4>
                <strong>Delivery Date:</strong>
              </h4>
              <h4>
                <span class="d-block font-weight-normal">{{ $helpers.formatDateTime(order.delivery_date) }}</span>
              </h4>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'OrderDetails',
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
  },
  props: {
    order: {
      required: true,
      type: Object,
    },
  },
}
</script>
