<template>
  <b-card
    no-body
    class="card-employee-task"
  >
    <b-card-header>
      <b-card-title class="text-uppercase">
        <h1>
          <strong>
            <FeatherIcon
              icon="NavigationIcon"
              size="30"
            />
            Sender Details
          </strong>
        </h1>
      </b-card-title>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-list-group flush>
            <b-list-group-item class="border-0 py-0">
              <h4>
                <strong>Sender's Name:</strong>
              </h4>
              <h4 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.user.name }}</span>
              </h4>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h4>
                <strong>Sender's Address:</strong>
              </h4>
              <h4 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.sender.complete }}</span>
              </h4>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h4>
                <strong>Person In Charge:</strong>
              </h4>
              <h4 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.sender.name }}</span>
              </h4>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h4>
                <strong>Phone Number:</strong>
              </h4>
              <h4>
                <span class="d-block font-weight-normal">{{ order.sender.phone }}</span>
              </h4>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'DeliveryOrigin',
  props: {
    order: {
      required: true,
      type: [Object, Array],
    },
  },
}
</script>

<style>

</style>
